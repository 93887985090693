@import "scss/index";
@include media-desktop {

  .widthContainer {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
  }

  .narrow {
    max-width: 618px;
  }
}
