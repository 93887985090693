@import "scss/index";
.widthContainerCompensator {
  width: 100vw;
}

@include media-mobile {

  .widthContainerCompensator {
    margin-right: -24px;
    margin-left: -24px;
  }
}

@include media-tablet {

  .widthContainerCompensator {
    margin-right: -32px;
    margin-left: -32px;
  }
}

@media (min-width: 1025px) and (max-width: 1279px) {

  .widthContainerCompensator {
    margin-right: -40px;
    margin-left: -40px;
  }
}

// Wide
@media (min-width: 1280px) {

  .widthContainerCompensator {
    margin-left: calc((100vw - 1200px) / 2 * -1);
    margin-right: calc((100vw - 1200px) / 2 * -1);
  }
}
