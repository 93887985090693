@import "scss/index";
.child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
}

.fitCover {
  object-fit: cover;
}

.fitContain {
  object-fit: contain;
}
