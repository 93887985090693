@import "scss/index";
$gutterSizes: 4, 8, 12, 16, 24, 32, 48;


.cell {
  flex-grow: 0;
  flex-shrink: 0;

  @include mouse-focus {
    outline: none;
  }
}

@each $size in $gutterSizes {
  .gutter-#{$size} {
    padding: #{$size / 2}px;
  }
}
