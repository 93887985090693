@import "scss/index";
.admin {
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: calc(100% - 296px - 40px) 296px;
  justify-content: space-between;
}

.user {
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: calc(100% - 293px - 40px) 293px;
  justify-content: space-between;
}

.modal {
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: 379px calc(100% - 379px - 32px);
  justify-content: space-between;
}

