@import "scss/index";
.containerX {
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &.withScrollbar {
    &::-webkit-scrollbar-thumb {
      background-color: $color-arrival;
      border-radius: 2px;
    }
  }
}

.containerY {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &.withScrollbar {
    &::-webkit-scrollbar-thumb {
      background-color: $color-arrival;
      border-radius: 2px;
    }
  }
}
